﻿@import "variables.scss";

// h1 {
//     color: #B22222;
// }

// h4 {
//     color: #B22222;
// }

// h6 {
//     color: #B22222
// }

* {
    box-sizing: border-box;
}

.formErrors {
    color: #000;
    font-weight: 500;
    border-color: #ffd800;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.row-spacing {
    padding-top: 10px;
}

.page-title {
    font-weight: bold;
    // color: $maroon;
}

div.min-padding a, div.min-padding button {
    margin-top: 0;
    padding-top: 0;
}

.contDetails {
    border-width: 1px;
    border-color: #000000;
    border: none;
}

span.applied-filter {
    font-size: 12pt;
    color: #999999;
    font-style: italic;
    padding-left: 12px;
}

.filter-display {
    background-color: #f0f0f0;
    margin: 12px;
    font-size: 11pt;
    font-weight: bold;
    color: $infoTeal;
}

.filter,
.sort-header,
.sort-handle {
    cursor: pointer;
}

span.applied-filter span.title {
    font-weight: bold;
    color: #666;
    font-style: normal;
    padding-right: 8px;
}

div.filter-definition-row {
    margin-top: 8px;
    padding: 12px;
    background-color: #eeeeee;
}

.clear-filters {
    margin-top: 18px;
}

a, .btn-link {
    color: $navy !important;
}


.text-primary {
    color: $primaryBlue !important;
}

.text-secondary {
    color: $secondaryGray !important;
}

.text-success {
    color: $successGreen !important;
}

.text-info {
    color: $infoTeal !important;
}

.text-warning {
    color: $warningYellow !important;
}

.text-danger {
    color: $dangerRed !important;
}

.border-primary {
    border-color: $primaryBlue !important;
}

.border-secondary {
    border-color: $secondaryGray !important;
}

.border-success {
    border-color: $successGreen !important;
}

.border-info {
    border-color: $infoTeal !important;
}

.border-warning {
    border-color: $warningYellow !important;
}

.border-danger {
    border-color: $dangerRed !important;
}

.primary {
    background-color: $primaryBlue !important;
    border-color: $primaryBlue !important;
    color: white !important;

    &.btn:hover {
        background-color: $primaryBlueDarker !important;
        color: white !important;
    }

    &.btn-outline-secondary {
        background-color: #FFFFFFCC !important;
        border-color: $primaryBlue !important;
        color: $primaryBlue !important;

        &.active {
            background-color: $primaryBlue !important;
            border-color: $primaryBlue !important;
            color: white !important;

            &.btn:hover {
                background-color: $primaryBlueDarker !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $primaryBlue !important;
        color: $primaryBlue !important;
    }

    &.list-group-item {
        background-color: #FFFFFFCC !important;
        border-color: $primaryBlue !important;
        color: $primaryBlue !important;

        &.active {
            background-color: $primaryBlue !important;
            border-color: $primaryBlue !important;
            color: white !important;

            &.btn:hover {
                background-color: $primaryBlueDarker !important;
                color: white !important;
            }
        }
    }
}

.secondary {
    background-color: $secondaryGray !important;
    border-color: $secondaryGray !important;
    color: white !important;

    &.btn:hover {
        background-color: $secondaryGrayDarker !important;
        color: white !important;
    }

    &.btn-outline-secondary {
        background-color: #FFFFFFCC !important;
        border-color: $secondaryGray !important;
        color: $secondaryGray !important;

        &.active {
            background-color: $secondaryGray !important;
            border-color: $secondaryGray !important;
            color: white !important;

            &.btn:hover {
                background-color: $secondaryGrayDarker !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $secondaryGray !important;
        color: $secondaryGray !important;
    }
}

.success {
    background-color: $successGreen !important;
    border-color: $successGreen !important;
    color: white !important;

    &.btn:hover {
        background-color: $successGreenDarker !important;
        color: white !important;
    }

    &.btn-outline-secondary {
        background-color: #FFFFFFCC !important;
        border-color: $successGreen !important;
        color: $successGreen !important;

        &.active {
            background-color: $successGreen !important;
            border-color: $successGreen !important;
            color: white !important;

            &.btn:hover {
                background-color: $successGreenDarker !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $successGreen !important;
        color: $successGreen !important;
    }
}

.info {
    background-color: $infoTeal !important;
    border-color: $infoTeal !important;
    color: white !important;

    &.btn:hover {
        background-color: $infoTealDarker !important;
        color: white !important;
    }

    &.btn-secondary {
        background-color: $infoTeal !important;
        border-color: $infoTeal !important;
        color: white !important;

        &.btn:hover {
            background-color: $infoTealDarker !important;
            color: white !important;
        }
    }

    &.btn-outline-secondary, .card-outline {
        background-color: #FFFFFFCC !important;
        border-color: $infoTeal !important;
        color: $infoTeal !important;

        &.active {
            background-color: $infoTeal !important;
            border-color: $infoTeal !important;
            color: white !important;

            &.btn:hover {
                background-color: $infoTealDarker !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $infoTeal !important;
        color: $infoTeal !important;
    }
}

.warning {
    background-color: $warningYellow !important;
    border-color: $warningYellow !important;
    color: white !important;

    &.btn:hover {
        background-color: $warningYellowDarker !important;
        color: white !important;
    }

    &.btn-outline-secondary {
        background-color: #FFFFFFCC !important;
        border-color: $warningYellow !important;
        color: $warningYellow !important;

        &.active {
            background-color: $warningYellow !important;
            border-color: $warningYellow !important;
            color: white !important;

            &.btn:hover {
                background-color: $warningYellowDarker !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $warningYellow !important;
        color: $warningYellow !important;
    }
}

.danger {
    background-color: $dangerRed !important;
    border-color: $dangerRed !important;
    color: white !important;

    &.btn:hover {
        background-color: $dangerRedDarker !important;
        color: white !important;
    }

    &.btn-outline-secondary {
        background-color: #FFFFFFCC !important;
        border-color: $dangerRed !important;
        color: $dangerRed !important;

        &.active {
            background-color: $dangerRed !important;
            border-color: $dangerRed !important;
            color: white !important;

            &.btn:hover {
                background-color: $dangerRedDarker !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $dangerRed !important;
        color: $dangerRed !important;
    }
}

.distinctiveLight {
    background-color: $lightBeige !important;
    border-color: $lightBeige !important;
    color: #000 !important;

    &.btn:hover {
        background-color: $beige !important;
        color: #000 !important;
    }

    &.btn-outline-secondary, .card-outline {
        background-color: #FFFFFFCC !important;
        border-color: $lightBeige !important;
        color: #000 !important;

        &.active {
            background-color: $lightBeige !important;
            border-color: $lightBeige !important;
            color: #000 !important;

            &.btn:hover {
                background-color: $beige !important;
                color: #000 !important;
            }
        }
    }
}

.dark {
    background-color: $secondaryGrayDarker !important;
    border-color: $secondaryGrayDarker !important;
    color: white !important;

    &.btn:hover {
        background-color: #000 !important;
        color: white !important;
    }

    &.btn-outline-secondary {
        background-color: #FFFFFFCC !important;
        border-color: $secondaryGrayDarker !important;
        color: $secondaryGrayDarker !important;

        &.active {
            background-color: $secondaryGrayDarker !important;
            border-color: $secondaryGrayDarker !important;
            color: white !important;

            &.btn:hover {
                background-color: #000 !important;
                color: white !important;
            }
        }
    }

    &.card-outline {
        background-color: transparent !important;
        border-color: $secondaryGrayDarker !important;
        color: $secondaryGrayDarker !important;
    }
}


.primaryBlueDarker {
    background-color: $primaryBlueDarker !important;
}

.infoTealDarker {
    background-color: $infoTealDarker !important;
}

.secondaryGray {
    background-color: $secondaryGray !important;
}

.react-select__value-container > div {
    width: auto !important;
}

.bold-title {
    font-weight: 700;
}

.rug-option-header {
    border: solid 1px #999999;
    background-color: #D4D4D4;
    font-size: 16pt;
    font-weight: bold;
    margin: 22px 0 0 0;
    height: 38px;
}

.rt {
    text-align: right;
}

.feet-text {
    padding-left: 0;
    padding-right: 4px;
    width: 35px;
    text-align: right;
}

.inches-text {
    padding-left: 0;
    padding-right: 4px;
    width: 25px;
    text-align: right;
}

.total-text {
    margin-top: 0;
    padding: 0 12px 0 4px;
    font-weight: bold;
    font-size: 10pt;
    border-style: none;
    text-align: right;
}
.total-text-left {
    margin-top: 0;
    padding: 0 12px 0 4px;
    font-weight: bold;
    font-size: 10pt;
    border-style: none;
    text-align: left;
}

.maintainSamplesTableHeader {
    // white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    max-width: 100px !important;
}
.maintainSampleCheckbox {
    height: 3vh;
    width: 3vw;
    position: relative;
    margin-top: 0;
    margin-left: 0;
}

.maintainCustomerColumn {
    width: 65px !important;
}
.maintainBookColumn {
    width: 50px !important;
}

.maintainSampleCheckboxContainer {
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
}

.backgroundPrimary {
    background-color: $primaryBlue;
}
.backgroundSecondary {
    background-color: $secondaryGray;
}
.backgroundWarning {
    background-color: $warningYellow;
}
.backgroundDanger {
    background-color: $dangerRed;
}
.backgroundSuccess {
    background-color: $successGreen;
}
.backgroundSuccessDarker {
    background-color: $successGreenDarker;
}
.backgroundPrimaryDarker {
    background-color: $primaryBlueDarker;
}
.report-description {
    font-weight: bold;
    font-size: 1.5rem;
    background-color: #F0F0F0;
    padding: 8px;
}
.boldText {
  font-weight: bold;
}
.buttonGroupSpacing {
  margin-right: 5px;
}
.font24{
  font-size: 24pt;
}
.font14 {
  font-size: 14pt;
}
.font10 {
  font-size: 10pt;
}

.highlightText {
  background-color: #ffff99 !important;
}
.centerText {
  text-align: center;
}
.expenseTotalStyle {
  background-color: #ccc !important;
  color: #fff !important;
  margin-right: 15px;
}

.expenseBackgroundStyle {
  background-color: #ccc !important;
  color: #fff !important;
}
.detailTextNoSize {
  color: #999999; /*grey;*/
  font-style: italic;
}

.allCaps {
  text-transform: uppercase;
}

.headerRowCheckbox {
    position: inherit;
}
a.nav-link {
    cursor: pointer;
}
td.rug-addl {
    background-color: #F5F5F5;
    padding-left: 20px;
}

body.hidden-scrollbar::-webkit-scrollbar {
    visibility: hidden;
 }

.visibilityHidden {
  visibility: hidden;
}

body.squarespaceContent {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}