@import "variables.scss";
@import "main.scss";
@import "agentAdmin.scss";
@import "header.scss";
@import "pagination.scss";

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
}

.priceColumn {
  background-color: #92e4c8;
}

.costColumn {
  background-color: #e0d5d5;
}
.totalColumn {
  background-color: #b9cedb;
  text-align: center;
  font-size: 1.15em;
}

.productButton {
  background-color: $softGreen !important;
  border-radius: 0 !important;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 300 !important;
}

.softGreenText {
  color: $softGreen !important;
}

.borderNone {
  border: none !important;
}

.card-columns {
  column-gap: 0.6rem;
}

@media (min-width: 0px) {
  .fifthColStyle {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    // font-size: 0.75em !important;
    // max-height: 250px !important;
    margin-bottom: 1.25em !important;
  }
}
@media (min-width: 992px) {
  .fifthColStyle {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 200%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 8px;
    padding-right: 8px;
    // font-size: 0.75em !important;
    // max-height: 250px !important;
    margin-bottom: 1.25em !important;
  }
}

// .fifthColStyle {
//   -webkit-box-flex: 0;
//   -ms-flex: 0 0 20%;
//   flex: 0 0 20%;
//   max-width: 20%;
//   padding-left: 8px;
//   padding-right: 8px;
//   // font-size: 0.75em !important;
//   // max-height: 250px !important;
//   margin-bottom: 1.25em !important;
// }

// .twoColStyle {
//   -webkit-box-flex: 0;
//   -ms-flex: 0 0 50%;
//   flex: 0 0 50%;
//   max-width: 50%;
//   padding-left: 8px;
//   padding-right: 8px;
//   // font-size: 0.75em !important;
//   // max-height: 250px !important;
//   margin-bottom: 1.25em !important;
// }

.tinyColumn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4%;
  flex: 0 0 4%;
  max-width: 4%;
}

.navbar-collapse {
  border-top: none !important;
}

.navbar-toggleable-md {
  padding: 8px !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.squarespaceButton {
  backface-visibility: hidden !important;
  background-attachment: scroll !important;
  background-clip: border-box !important;
  background-color: rgb(89, 117, 111) !important;
  background-image: none !important;
  background-origin: padding-box !important;
  background-position-x: 0% !important;
  background-position-y: 0% !important;
  background-size: auto !important;
  border-bottom-color: rgb(89, 117, 111) !important;
  border-left-color: rgb(89, 117, 111) !important;
  border-right-color: rgb(89, 117, 111) !important;
  border-top-color: rgb(89, 117, 111) !important;
  box-sizing: border-box !important;
  color: rgb(255, 255, 255) !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: Poppins !important;
  font-size: 19.2px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  height: 59.8438px !important;
  letter-spacing: 0.384px !important;
  line-height: 23.04px !important;
  margin-bottom: 29.34px !important;
  margin-left: 29.34px !important;
  margin-right: 29.34px !important;
  margin-top: 19.56px !important;
  min-width: 210px !important;
  opacity: 1 !important;
  overflow-wrap: break-word !important;
  padding-bottom: 18.432px !important;
  padding-left: 30.7814px !important;
  padding-right: 30.7814px !important;
  padding-top: 18.432px !important;
  pointer-events: auto !important;
  position: relative !important;
  text-align: center !important;
  text-decoration-color: rgb(255, 255, 255) !important;
  text-decoration-line: none !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: auto !important;
  text-size-adjust: 100% !important;
  text-transform: none !important;
  transition-delay: 0s !important;
  transition-duration: 0.1s !important;
  transition-property: opacity !important;
  transition-timing-function: linear !important;
  user-select: none !important;
  visibility: visible !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.squarespaceText {
  font-family: Poppins !important;
  color: rgb(89, 117, 111) !important;
}

.width50Percent {
  width: 50% !important;
}

.squarespaceCTAButton {
  max-width: 148px !important;
  max-height: 48px !important;
}

.sqLoginButton {
  backface-visibility: hidden !important;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: auto;
  white-space: nowrap;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 0.96em 1.6032em;
  outline: 0;
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  text-transform: none;
  line-height: 1.2em;
  font-size: calc((0.9 - 1) * 1.2vw + 1rem);
  transition: 0.1s opacity linear;
  color: #fff;
  opacity: 1 !important;
  background-color: rgb(89, 117, 111) !important;
  border-color: rgb(89, 117, 111) !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: Poppins !important;
  font-size: 19.2px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  letter-spacing: 0.384px !important;

  // line-height: 23.04px !important;
  // height: 45px !important;
  // width: 145px !important;
  // opacity: 1 !important;
  // overflow-wrap: break-word !important;
  // padding-bottom: 18.432px !important;
  // padding-left: 30.7814px !important;
  // padding-right: 30.7814px !important;
  // padding-top: 18.432px !important;
  // pointer-events: auto !important;
  // position: relative !important;
  // text-align: center !important;
  // text-decoration-color: rgb(255, 255, 255) !important;
  // text-decoration-line: none !important;
  // text-decoration-style: solid !important;
  // text-decoration-thickness: auto !important;
  // text-size-adjust: 100% !important;
  // text-transform: none !important;
  // transition-delay: 0s !important;
  // transition-duration: 0.1s !important;
  // transition-property: opacity !important;
  // transition-timing-function: linear !important;
  // user-select: none !important;
  // visibility: visible !important;
  // -webkit-font-smoothing: antialiased !important;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.stickyDupButton {
  z-index: 100;
  position: fixed;
  top: 35px;
  right: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

// old cart style sidebar sticky to side of div style
// .sqSidebarButton {
//   min-height: 50px !important;
//   width: 50px !important;
//   max-height: 112px !important;
//   background-color: rgb(89, 117, 111) !important;
//   border-color: rgb(89, 117, 111) !important;
//   color: #fff !important;
//   font-family: Poppins !important;
//   font-size: 19.2px !important;
//   font-style: normal !important;
//   font-weight: 500 !important;
//   letter-spacing: 0.384px !important;
// }

.maxWidth90 {
  max-width: 90px !important;
}

.minWidth70 {
  min-width: 70px !important;
}

.maxWidth150 {
  max-width: 150px !important;
}

.sqSidebarButton {
  background-color: rgb(89, 117, 111) !important;
  border-color: rgb(89, 117, 111) !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-style: normal !important;
  letter-spacing: 0.384px !important;
  border-radius: 0 !important;
  .disabled {
    background-color: rgba(89, 117, 111, 0.4) !important;
  }
}

.sqSidebarButtonOutline {  
  background-color: #fff !important;
  border-color: rgb(89, 117, 111) !important;
  color: rgb(89, 117, 111) !important;
  font-family: Poppins !important;
  font-style: normal !important;
  letter-spacing: 0.384px !important;
  border-radius: 0 !important;
  min-height: 38px !important;
}

.minHeight39 {
  min-height: 39px !important;
}

.userCartSection {
  width: 135px !important;
  overflow-x: hidden;
}
// react-select for pagination in Squarespace screens
//needs more tweaking than I have time for right now: Melissa 
// .paginationSelector, .css-yk16xz-control, .css-g1d714-ValueContainer, .css-1pahdxg-control {
//   min-width: 100px !important;
//   border-radius: 0em !important;
//   height: 30.8px !important;
//   color: rgb(89, 117, 111) !important;
//   .css-2613qy-menu {
//     -ms-overflow-style: none;  /* IE and Edge */
//     scrollbar-width: none;  /* Firefox */
//     overflow-y: scroll;
//   }
//   .css-2613qy-menu::-webkit-scrollbar {
//     display: none;
//   }
// }
// .paginationSelector {
//   select__menu-list::-webkit-scrollbar {
//     width: 1px !important;
//     height: 0px;
//   }
// }

.maxWidth75 {
  max-width: 75px !important;
}
.maxWidth200 {
  max-width: 200px !important;
}
.maxWidth250 {
  max-width: 250px !important;
}

.minWidth200 {
  min-width: 200px !important;
}
.minWidth125 {
  min-width: 125px !important;
}

.minHeight250 {
  min-height: 250px !important;
}
.maxHeight300 {
  max-height: 300px !important;
}
.maxHeight350 {
  max-height: 350px !important;
}

.minHeight400 {
  min-height: 400px !important;
}

.turn90 {
  transform: rotate(-90deg);
  transform-origin: right, top;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin: right, top;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: right, top;
  display: inline-block;
  min-width: 265px !important;
  padding-bottom: 5px !important;
}

.rugStepper {
  border-right: 2px rgb(89, 117, 111) solid;
}

.rugStep {
  margin-top: 125px !important;
  margin-left: -9em !important;
}

@media (min-width: 576px) {
  .rugStep {
    margin-left: -8em !important;
  }
}
@media (min-width: 768px) {
  .rugStep {
    margin-left: -7em !important;
  }
}
@media (min-width: 992px) {
  .rugStep {
    margin-left: -6em !important;
  }
}
@media (min-width: 1200px) {
  .rugStep {
    margin-left: -5em !important;
  }
}



.marginLeftNeg105 {
  margin-left: -105px !important;
}

.stickyDupButton {
  z-index: 100;
  position: fixed;
  top: 55px;
  right: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.imageBindingRow {
  height: 25px !important;
}

.image2ndSection {
  height: 375px !important;
}

.fieldImage {
  max-height: 300px !important;
}

.imageBindingCol {
  max-width: 25px !important;
}

.borderCol {
  max-width: 50px !important;
}

.borderRow {
  height: 50px !important;
}
// .fieldCol {
//   max-width: 275px !important;
// }

.grayBorder {
  background-color: rgb(196, 196, 196);
}

.greenBinding {
  background-color: rgb(157, 177, 173);
}

.customRugCard {
  min-height: 453px !important;
}

// .imgKeyItemCard {
//   max-height: 205px !important;
// }

.squarespaceContainer, .squarespaceContainer.container {
  height: 100% !important;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: .8rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: none !important;
}

// @media (min-width: 576px) {
//   .squarespaceContainer.container {
//     max-width: 540px !important;
//   }
// }
// @media (min-width: 768px) {
//   .squarespaceContainer.container {
//     max-width: 748px !important;
//   }
// }
// @media (min-width: 992px) {
//   .squarespaceContainer.container {
//     max-width: 972px !important;
//   }
// }
// @media (min-width: 1200px) {
//   .squarespaceContainer.container {
//     max-width: 1180px !important;
//   }
// }
// @media (min-width: 1200px) {
//   .squarespaceContainer.container {
//     max-width: none !important;
//   }
// }


.filterSection {
  min-height: 45px !important;
  // position: fixed;
  // top: 0;
  // right: 0;
  width: 100%;
  z-index: 30 !important;
  background-color: #fff;
}
.productsSection {
  padding-top: 10px;
  float: left !important;
  // height: 650px;
}
.invisibleColumn {
  width: 60px !important;
  max-width: 60px !important;
}
.productsRow {
  // height: 605px;
  height: 100% !important;
  overflow-y: visible !important;
}
//.productCardColumn {
  // max-height: 175px !important;
//}

// .productsSection::-webkit-scrollbar,
// .productsRow::-webkit-scrollbar {
//   display: none;
// }
// /* Hide scrollbar for IE, Edge and Firefox */
// .productsSection, .productsRow {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }
.squarespacePager {
  min-height: 50px !important;
  padding-top: 5px;
}

.fontSizeTurnItUpTo11 {
  font-size: 110%;
}

.cartWidth {
  width: calc(100vw - 35px);
  flex: 1;
  flex-direction: column;
}

.sidebarWidth {
  width: calc(100vw - 15px);
}

input.currency {
  text-align: right;
  width: 150px;
}

.mainSquarespaceContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-right: 0;
}

.squarespaceContent {
  flex: 1;
}
.squarespaceFooter {  
  height: 305px;
  flex: none;
  z-index: 2;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border: none;
}

// .applyOpacity {
//   background-color: rgba(0, 0, 0, 0.3);
// }
.redBackground {
  background-color: red !important;
}