﻿

#page-list {
    float: left;
}

#page-number {
    list-style: none;
    display: flex;
    padding-left: 0;
}

#page-number-title {
    color: black !important;
}


#page-number > li {
    margin-right: 0.3em;
    color: blue;
    user-select: none;
    cursor: pointer;
}

