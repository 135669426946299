$beige: #72aa8a;
$navy: #060675;
$maroon: #9E160D;

$lightBeige: #94b9a4;
// $primaryBlue: #2c2cac;
$primaryBlue: #0000a0;
$primaryBlueDark: #1e1e90;
$primaryBlueDarker: $navy;
$successGreen: #0b7724;
$successGreenDark: #0b5f1f;
$successGreenDarker: #094617;
$warningYellow: #d1971a;
$warningYellowDark: #af7e15;
$warningYellowDarker: #906403;
$dangerRed: #8d0808;
$dangerRedDark: #701212;
$dangerRedDarker: $maroon;
$secondaryGray: #979592;
$secondaryGrayDark: #706e6c;
$secondaryGrayDarker: #575554;
$infoTeal: #437a80;
$infoTealDark: #315b5f;
$infoTealDarker: #254346;

$softGreen: #59756f;

